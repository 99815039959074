<template>
  <v-card>
    <v-card-title>Create Awesome 🙌</v-card-title>
    <v-card-text>This is your second page.</v-card-text>
    <v-card-text>
      Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin.
      Carrot cake dragée chupa chups jujubes. Macaroon liquorice cookie
      wafer tart marzipan bonbon. Gingerbread jelly-o dragée
      chocolate.
    </v-card-text>
  </v-card>
</template>

<script>
import request from '@/utils/request'

export default {
  data() {
    return {
      apiMessage: '',
    }
  },
  async mounted() {
    await this.getArea()
  },
  methods: {
    async getArea() {
      const token = await this.$auth.getTokenSilently()
      const area = await request({
        url: '/tickets',
        method: 'get',
        params: {
          page: 1,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      console.log(area)
    },
  },
}
</script>
